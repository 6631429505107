import {
  existsSync,
  mkdirSync,
  readdirSync,
  readFileSync,
  writeFileSync
} from 'fs';
import path from 'path';

import { publicDataDumpDirName } from '../constants/path';
import {
  logError,
  logReadFile,
  logWithPrefix,
  logWriteFile
} from './log-helpers';

const publicDir = `./public/${publicDataDumpDirName}`;
const cacheDir = './.next/cache';

// Write file with logging
export const writeFileWithLog = ({
  content,
  filePath,
  logPrefix,
  noSuccessLog = false,
}) => {
  const dir = path.dirname(filePath);

  if (!existsSync(dir)) {
    // Create folder if it doesn't exist
    mkdirSync(dir, { recursive: true });
  }

  try {
    writeFileSync(filePath, content);

    if (!noSuccessLog) {
      // Useful if used within a loop and we don't want the noise
      logWriteFile({
        filePath,
        prefix: logPrefix,
      });
    }

    return true;
  } catch (error) {
    logWriteFile({
      err: error,
      filePath,
      prefix: logPrefix,
    });
  }

  return false;
};

export const readFileWithLog = ({
  defaultValue = {},
  filePath,
  logPrefix,
  noSuccessLog = false,
}) => {
  if (!existsSync(filePath)) {
    logWithPrefix({
      message: `Attempting to read non-existent file: ${filePath}`,
      prefix: logPrefix,
      type: 'warn',
    });

    return defaultValue;
  }

  // Parse the content
  try {
    const content = readFileSync(filePath, { encoding: 'utf8' });

    if (!noSuccessLog) {
      // Useful if used within a loop and we don't want the noise
      logReadFile({
        filePath,
        prefix: logPrefix,
      });
    }

    return JSON.parse(content);
  } catch (error) {
    logReadFile({
      err: error,
      filePath,
      prefix: logPrefix,
    });

    throw error;
  }
};

export const writeFileToPublicWithLog = ({ filePath, ...args }) => {
  return writeFileWithLog({
    ...args,
    filePath: path.join(publicDir, filePath),
  });
};

export const readPublicFileWithLog = ({ filePath, ...args }) => {
  return readFileWithLog({
    ...args,
    filePath: path.join(publicDir, filePath),
  });
};

export const writeFileToBuildCache = ({ filePath, ...args }) => {
  return writeFileWithLog({
    ...args,
    filePath: path.join(cacheDir, filePath),
  });
};

export const readFileFromBuildCache = ({ filePath, ...args }) => {
  return readFileWithLog({
    ...args,
    filePath: path.join(cacheDir, filePath),
  });
};

export const fetchFromPublicWithLog = async ({
  defaultValue = {},
  filePath,
  logPrefix,
  noSuccessLog = false,
}) => {
  let baseUrl = 'http://localhost:3000';

  if (process.env.VERCEL) {
    let domain = 'www.rippling.com';

    if (process.env.VERCEL_ENV === 'production') {
      domain = process.env.VERCEL_PROJECT_PRODUCTION_URL;
    } else {
      domain = process.env.VERCEL_BRANCH_URL;
    }

    baseUrl = `https://${domain}`;
  }

  const url = `${baseUrl}/${publicDataDumpDirName}/${filePath}`;

  try {
    const resp = await fetch(url, { headers: { 'x-rpl-data-token': process.env.NEXT_PUBLIC_RPL_DATA_TOKEN } });

    if (!noSuccessLog) {
      logWithPrefix({
        message: `Fetched from url: ${url}`,
        prefix: logPrefix,
      });
    }

    return await resp.json();
  } catch (error) {
    logError({
      err: error,
      message: `Failed to fetch from url: ${url}`,
      prefix: logPrefix,
    });
  }

  return defaultValue;
};

export const readOrFetchFromPublicWithLog = async ({ filePath, ...args }) => {
  if (!process.env.VERCEL || process.env.CI === '1') {
    // Always read
    return readPublicFileWithLog({
      filePath,
      ...args,
    });
  }

  return await fetchFromPublicWithLog({
    ...args,
    filePath,
  });
};

// Return all .json files in the cache directory
export const getJsonFilesFromCacheDir = ({ filePath }) => {
  if (!process.env.VERCEL || process.env.CI === '1') {
    const regex = /(.*)\.json$/;
    const cacheFilePath = path.join(cacheDir, filePath);

    if (existsSync(cacheFilePath)) {
      return readdirSync(cacheFilePath).filter((fileName) =>
        regex.test(fileName));
    }
  }

  return [];
};
